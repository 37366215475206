import { SET_STRIPE_DATA } from "../actionTypes";

const initialState = {
  subscription: null,
  customer: null,
  paymentMethod: null,
};

const stripeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STRIPE_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
export default stripeReducer;
