// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-a-index-jsx": () => import("./../src/pages/a/index.jsx" /* webpackChunkName: "component---src-pages-a-index-jsx" */),
  "component---src-pages-account-deleted-jsx": () => import("./../src/pages/account-deleted.jsx" /* webpackChunkName: "component---src-pages-account-deleted-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-index-jsx": () => import("./../src/pages/privacy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-index-jsx" */),
  "component---src-pages-terms-index-jsx": () => import("./../src/pages/Terms/index.jsx" /* webpackChunkName: "component---src-pages-terms-index-jsx" */)
}

