export const ADD_PLAID_ACCESS_TOKEN = "ADD_PLAID_ACCESS_TOKEN";

export const SET_SPLITWISE_USER = "SET_SPLITWISE_USER";
export const SET_SPLITWISE_FRIENDS = "SET_SPLITWISE_FRIENDS";
export const SET_SPLITWISE_GROUPS = "SET_SPLITWISE_GROUPS";
export const SET_SPLITWISE_LOADED = "SET_SPLITWISE_LOADED";
export const SET_SPLITWISE_ERROR = "SET_SPLITWISE_ERROR";

export const SET_SPLITWISE_EXPENSES = "SET_SPLITWISE_EXPENSES";

export const INIT_SERVER_ACITON = "";

export const SET_STRIPE_DATA = "SET_STRIPE_DATA";
