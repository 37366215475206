class API {
  DEV_BASE_URL = "https://us-central1-autosplit-270a6.cloudfunctions.net/";

  DEV_STRIPE_PUBLIC_KEY = "pk_test_kjLn2dRheSNaVtOFqevVlekV00sdTRPqXs";

  PROD_BASE_URL = "https://us-central1-reconcile-prod.cloudfunctions.net/";

  PROD_STRIPE_PUBLIC_KEY = "pk_live_S9ZhcKUBKJ02DrSJRI0w6Ttj00qUa3hbkR";

  // only one of these
  PLAID_PUBLIC_KEY = "8f0ad8492c678dd1a5061416b12405";

  useLocal = false; // this should be ignored on the server

  PRODUCTION = "production";

  FIREBASE_LOCAL_HOST = "http://localhost:5001";

  getBaseUrl() {
    if (this.isProd()) {
      return this.PROD_BASE_URL;
    }
    if (this.useLocal) {
      return `${this.getFirebaseLocalHost()}/autosplit-270a6/us-central1/`;
    }
    return this.DEV_BASE_URL;
  }

  getFirebaseLocalHost() {
    return this.FIREBASE_LOCAL_HOST;
  }

  isProd() {
    return process.env.NODE_ENV === this.PRODUCTION || true; // force localhost to use prod services
  }

  getPlaidWebhookUrlForUid(uid) {
    return `${this.getBaseUrl()}plaidWebhook?uid=${uid}`;
  }

  getPlaidPublicKey() {
    return this.PLAID_PUBLIC_KEY;
  }

  getStripePublicKey() {
    return this.isProd()
      ? this.PROD_STRIPE_PUBLIC_KEY
      : this.DEV_STRIPE_PUBLIC_KEY;
  }
}

export default new API();
