/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

/* eslint-disable */

import React, { useEffect } from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import {
  ReactReduxFirebaseProvider,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";

import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

import "./src/styles/global.scss";

import createStore from "./src/redux/store";
import firebase from "./src/services/firebase";

import { fetchExternalData } from "./src/redux/actions";

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: green[600],
    },
  },
  status: {
    danger: "orange",
  },
});

// eslint-disable-next-line react/display-name,react/prop-types
export const wrapRootElement = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore();
  const rrfProps = {
    firebase: typeof window !== "undefined" ? firebase : {},
    config: {
      userProfile: "users",
      useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
    },
    dispatch: store.dispatch,
    createFirestoreInstance, // <- needed if using firestore
  };
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <ThemeProvider theme={theme}>
          <RootComponent>{element}</RootComponent>
        </ThemeProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

const RootComponent = ({ children }) => {
  const profile = useSelector(state => state.firebase.profile);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isLoaded(profile) && !isEmpty(profile)) {
      dispatch(fetchExternalData());
    }
  }, [profile, dispatch]);
  return <>{children}</>;
};
