import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import * as Sentry from "@sentry/browser";

import api from "./api";

const firebaseDevConfig = {
  apiKey: "AIzaSyBtf1DOr4G3qu3b4ARoTcC5cZKsuRbleak",
  authDomain: "autosplit-270a6.firebaseapp.com",
  databaseURL: "https://autosplit-270a6.firebaseio.com",
  projectId: "autosplit-270a6",
  storageBucket: "autosplit-270a6.appspot.com",
  messagingSenderId: "292287860485",
  appId: "1:292287860485:web:b1dd5850db0c680d43716c",
  measurementId: "G-SW7D39R29W",
};

const firebaseProdConfig = {
  apiKey: "AIzaSyC630LwarHRA4fHObAk7TFCQ7_Od8pid2g",
  authDomain: "reconcile-prod.firebaseapp.com",
  databaseURL: "https://reconcile-prod.firebaseio.com",
  projectId: "reconcile-prod",
  storageBucket: "reconcile-prod.appspot.com",
  messagingSenderId: "948780552744",
  appId: "1:948780552744:web:3f210b10511a502dc21b1d",
  measurementId: "G-YTVT9TLN1G",
};

if (api.isProd()) {
  firebase.initializeApp(firebaseProdConfig);
} else {
  firebase.initializeApp(firebaseDevConfig);
  if (api.useLocal)
    firebase.functions().useFunctionsEmulator(api.getFirebaseLocalHost());
}

firebase.firestore();
if (typeof window !== "undefined") {
  firebase.analytics();
}

export default firebase;

export const updateFlags = flags => {
  firebase
    .firestore()
    .doc(`users/${firebase.auth().currentUser.uid}`)
    .set(
      {
        flags: {
          ...flags,
        },
      },
      { merge: true }
    );
};

export const saveFilters = filters => {
  return firebase
    .firestore()
    .doc(`users/${firebase.auth().currentUser.uid}`)
    .set(
      {
        filters,
      },
      { merge: true }
    );
};

export const markSentToVenmo = async (transaction, remove = false) => {
  return firebase
    .firestore()
    .doc(
      `users/${firebase.auth().currentUser.uid}/transactions/${
        transaction.transaction_id
      }`
    )
    .set(
      {
        reconcileRecord: {
          sentToVenmo: remove ? null : new Date(),
          new: false,
        },
      },
      { merge: true }
    );
};

// would probably be better to do this witch batches, but can't gaurantee less than 500 writes
// and this will increase complexity.
export const markAllTransactionsReviewed = async () => {
  const promises = [];
  (
    await firebase
      .firestore()
      .collection(`users/${firebase.auth().currentUser.uid}/transactions`)
      .where("reconcileRecord.new", "==", true)
      .get()
  ).forEach(documentSnapshot =>
    promises.push(
      documentSnapshot.ref.set(
        { reconcileRecord: { new: false } }, // this shouldn't overwrite the full record but that should be confirmed.
        { merge: true }
      )
    )
  );
  return Promise.all(promises);
};

const gtag = typeof window !== `undefined` && window.gtag;

export const logEvent = (eventName, eventParams, options) => {
  firebase.analytics().logEvent(eventName, eventParams, options);
  gtag && gtag("event", eventName, eventParams);
};

export const initDeleteSplitwiseExpense = ({ expense, transaction_id }) => {
  logEvent("delete_splitwise_expense", {
    expense,
  });
  const deleteSplitwiseExpense = firebase
    .functions()
    .httpsCallable("deleteSplitwiseExpense");
  return deleteSplitwiseExpense({
    expense,
    transaction_id,
  }).catch(error => Sentry.captureException(error));
};

export const initSplitwiseExpense = ({ transaction, options }) => {
  logEvent("create_splitwise_expense", {
    transaction,
    options,
  });
  const initSplitwiseExpenseFn = firebase
    .functions()
    .httpsCallable("initSplitwiseExpense");
  return initSplitwiseExpenseFn({
    transaction,
    options,
  }).catch(error => Sentry.captureException(error));
};

export const deleteRecurringRule = recurRule => {
  const deleteRecurRule = firebase.functions().httpsCallable("deleteRecurRule");
  return deleteRecurRule({ recurRule }).catch(error =>
    Sentry.captureException(error)
  );
};

export const createAndChargeCustomer = async data => {
  const createAndChargeCustomerFn = firebase
    .functions()
    .httpsCallable("stripeCreateAndChargeCustomer");
  const response = await createAndChargeCustomerFn(data);
  return response.data;
};

export const cancelSubscription = async data => {
  const stripeCancelSubscription = firebase
    .functions()
    .httpsCallable("stripeCancelSubscription");
  const response = await stripeCancelSubscription(data);
  return response.data;
};

export const getStripePortalLink = async subscriptionId => {
  try {
    const stripeGetPortalUrl = firebase
      .functions()
      .httpsCallable("stripeGetPortalUrl");
    const { data } = await stripeGetPortalUrl({
      subscriptionId,
    });
    return data;
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
};

export const exchangePlaidToken = async (public_token, metadata) => {
  try {
    logEvent("exchange_plaid_token", { metadata });
    const plaidExchangeToken = firebase
      .functions()
      .httpsCallable("plaidExchangeToken");
    const result = await plaidExchangeToken({
      public_token,
      metadata,
    });
    return result;
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
};

export const removePlaidItem = plaid_item => {
  try {
    logEvent("remove_plaid_item", { plaid_item });
    const plaidRemoveItem = firebase
      .functions()
      .httpsCallable("plaidRemoveItem");
    return plaidRemoveItem(plaid_item);
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
};

export const attachPaymentMethod = data => {
  try {
    logEvent("attach_payment_method", data);
    const stripeAttachPaymentMethod = firebase
      .functions()
      .httpsCallable("stripeAttachPaymentMethod");
    return stripeAttachPaymentMethod(data);
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
};

export const storeSplitwiseToken = data => {
  try {
    logEvent("splitwise_store_token");
    return firebase.functions().httpsCallable("splitwiseStoreToken")(data);
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
};

export const deleteSplitwiseToken = () => {
  try {
    logEvent("splitwise_delete_token");
    return firebase.functions().httpsCallable("splitwiseDeleteToken")();
  } catch (error) {
    Sentry.captureException(error);
    return { error };
  }
};

/**
 *
 * @param {*} item_id provided if we are updating an existing item
 * @returns
 */
export const getPlaidLinkToken = async item_id => {
  try {
    logEvent("plaid_update_token");
    const {
      data: { link_token },
    } = await firebase.functions().httpsCallable("plaidGetLinkToken")({
      item_id,
    });
    return link_token;
  } catch (error) {
    Sentry.captureException(error);
    return { error };
  }
};

export const removeErrorFromItem = async item => {
  await firebase
    .firestore()
    .doc(`users/${firebase.auth().currentUser.uid}`)
    .set(
      {
        plaid_items: {
          [item.item_id]: {
            error: firebase.firestore.FieldValue.delete(),
          },
        },
      },
      { merge: true }
    );
};
