import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

import splitwise from "./splitwise";
import stripe from "./stripe";
import server from "./server";

export default combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  splitwise,
  server,
  stripe,
});
