import {
  SET_SPLITWISE_USER,
  SET_SPLITWISE_FRIENDS,
  SET_SPLITWISE_GROUPS,
  SET_SPLITWISE_ERROR,
  SET_SPLITWISE_LOADED,
} from "../actionTypes";

const initialState = {
  user: null,
  groups: [],
  friends: [],
  loaded: false,
  error: null,
};

const splitwiseReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SPLITWISE_USER: {
      const { user } = action.payload;
      return {
        ...state,
        user,
      };
    }
    case SET_SPLITWISE_FRIENDS: {
      const { friends } = action.payload;
      return {
        ...state,
        friends,
      };
    }
    case SET_SPLITWISE_GROUPS: {
      const { groups } = action.payload;
      return {
        ...state,
        groups,
      };
    }
    case SET_SPLITWISE_LOADED: {
      return {
        ...state,
        loaded: true,
      };
    }
    case SET_SPLITWISE_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        loaded: true,
        error,
      };
    }

    default:
      return state;
  }
};

export default splitwiseReducer;
