import * as Sentry from "@sentry/browser";

import {
  ADD_PLAID_ACCESS_TOKEN,
  SET_SPLITWISE_USER,
  SET_SPLITWISE_FRIENDS,
  SET_SPLITWISE_GROUPS,
  SET_STRIPE_DATA,
} from "./actionTypes";

import firebase, { logEvent } from "../services/firebase";

const getUserFromState = state => state.firebase.auth;

export const fetchExternalData = () => (dispatch, getState) => {
  dispatch(fetchSplitwiseData());
  dispatch(fetchStripeData());
};

export const deleteSplitwiseToken = () => (dispatch, getState) => {
  const user = getUserFromState(getState());
  if (user) {
    firebase
      .firestore()
      .doc(`users/${user.uid}`)
      .set({ splitwiseToken: null }, { merge: true })
      .catch(error => Sentry.captureException(error));
  }
};

export const writeSplitwiseToken = splitwiseToken => (dispatch, getState) => {
  const user = getUserFromState(getState());
  logEvent("write_splitwise_token", { user });
  if (user) {
    firebase
      .firestore()
      .doc(`users/${user.uid}`)
      .set({ splitwiseToken }, { merge: true })
      .catch(error => Sentry.captureException(error));
  }
};

export const addPlaidAccessToken = accessToken => ({
  type: ADD_PLAID_ACCESS_TOKEN,
  payload: {
    accessToken,
  },
});
// TODO: this should be moved to the server
export const exchangePlaidToken = (public_token, metadata) => (
  dispatch,
  getState
) => {
  logEvent("exchange_plaid_token", { metadata });
  const plaidExchangeToken = firebase
    .functions()
    .httpsCallable("plaidExchangeToken");
  return plaidExchangeToken({
    public_token,
    metadata,
  }).catch(error => Sentry.captureException(error));
};

export const fetchPlaidTransactions = () => (dispatch, state) => {
  const plaidFetchTransactions = firebase
    .functions()
    .httpsCallable("plaidFetchTransactions");
  return plaidFetchTransactions().catch(error =>
    Sentry.captureException(error)
  );
};

const setSplitwiseUser = user => ({
  type: SET_SPLITWISE_USER,
  payload: { user },
});

const setSplitwiseFriends = friends => ({
  type: SET_SPLITWISE_FRIENDS,
  payload: { friends },
});

const setSplitwiseGroups = groups => ({
  type: SET_SPLITWISE_GROUPS,
  payload: { groups },
});

const hasLinkedSplitwise = state => {
  return (
    state &&
    state.firebase &&
    state.firebase.profile &&
    state.firebase.profile.hasLinkedSplitwise
  );
};

export const fetchSplitwiseData = () => async (dispatch, getState) => {
  if (!hasLinkedSplitwise(getState())) return;
  const {
    data: { user, friends, groups },
  } = await firebase.functions().httpsCallable("splitwiseFetchData")();
  if (!user) return;
  dispatch(setSplitwiseUser(user));
  dispatch(
    setSplitwiseFriends(
      friends &&
        friends.sort(
          (a, b) => b.updated_at.localeCompare(a.updated_at) // sort by `updated_at` desc so most recently used friends are first
        )
    )
  );
  dispatch(
    setSplitwiseGroups(
      groups &&
        groups
          .filter(g => g.id !== 0)
          .sort(
            (a, b) => b.updated_at.localeCompare(a.updated_at) // sort by `updated_at` desc so most recently used groups are first
          )
    )
  );
};

export const initSplitwiseExpense = ({ transaction, options }) => (
  dispatch,
  getState
) => {
  if (!hasLinkedSplitwise(getState())) {
    throw Error("user has not linked splitwise");
  }
  logEvent("create_splitwise_expense", {
    transaction,
    options,
  });
  const initSplitwiseExpenseFn = firebase
    .functions()
    .httpsCallable("initSplitwiseExpense");
  return initSplitwiseExpenseFn({
    transaction,
    options,
  });
};

const getSubscriptionIdFromState = state =>
  state.firebase &&
  state.firebase.profile &&
  state.firebase.profile.subscription &&
  state.firebase.profile.subscription.id;

export const fetchStripeData = () => async (dispatch, getState) => {
  const subscriptionId = getSubscriptionIdFromState(getState());
  try {
    if (!subscriptionId) throw Error("error: subscriptionId not defined");
    const stripeGetInfoForSubscription = firebase
      .functions()
      .httpsCallable("stripeGetInfoForSubscription");
    const { data } = await stripeGetInfoForSubscription({ subscriptionId });
    return dispatch(setStripeData(data));
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

const setStripeData = data => ({
  type: SET_STRIPE_DATA,
  payload: { ...data },
});
